import { render, staticRenderFns } from "./HsIndex.vue?vue&type=template&id=3949f61c&scoped=true&"
import script from "./HsIndex.vue?vue&type=script&lang=js&"
export * from "./HsIndex.vue?vue&type=script&lang=js&"
import style0 from "./HsIndex.vue?vue&type=style&index=0&id=3949f61c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3949f61c",
  null
  
)

export default component.exports