<template>
  <div class="page-container">
    <home-swiper :banner="banner"/>
    <van-notice-bar left-icon="volume-o" :scrollable="false" background="#ffffff"
                    color="#ff5100">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item v-for="item in notice" :key="item.id">{{item.content}}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <brand-list :brand="brand"></brand-list>
  </div>
</template>

<script>
  import { getHsIndexData } from 'api/api'
  import HomeSwiper from '../home/childComps/HomeSwiper'
  import BrandList from './childComps/BrandList'

  export default {
    name: 'HsIndex',
    components: {
      HomeSwiper,
      BrandList
    },
    data () {
      return {
        banner: [],
        notice: [],
        brand: [],
      }
    },
    methods: {
      getHsIndexDataFn () {
        getHsIndexData().then(res => {
          document.title = res.product
          this.banner = res.banner
          this.notice = res.notice
          this.brand = res.brand
        })
      }
    },
    created () {
      this.getHsIndexDataFn()
    },
  }
</script>

<style lang="less" scoped>
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
</style>
