<template>
  <div class="brand-price-wrapper">
    <div class="brand-list-wrapper" v-for="mainItem in brand" >
      <div class="title-wrapper">
        <div class="title">&gt;&gt;&gt; {{mainItem.title}} &lt;&lt;&lt;</div>
      </div>
      <div class="brand-list">
        <div class="brand-item" v-for="item in mainItem.list" @click="goToPrice(item.brand_id,item.title)">
          <img class="brand-logo" :src="baseImageUrl + item.logo_url" alt="" :key="item.id">
          <div>{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BrandList',
    data(){
      return{
        baseImageUrl : 'https://ljh-web-wx-1253863388.file.myqcloud.com/other/protection_logo'
      }
    },
    props:{
      brand:Array
    },
    methods:{
      goToPrice(index,title){
        this.$router.push({
          path:'/price',
          query:{
            index:index,
            title:title
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .brand-price-wrapper {
    margin-bottom: 0.3rem;
    background-color: #fff;
    .brand-list-wrapper {
      width: 100%;
      border-radius: 0.1rem;
      margin-bottom: 0.2rem;
      padding-bottom: 0.3rem;
      background-color: #39B14D;
      .title-wrapper {
        text-align: center;
        display: flex;
        align-items: center;
        padding: 0.2rem 0;
        margin: 0 0.3rem;
        position: relative;
        color: #fff;

        .title {
          text-align: center;
          padding-left: 0.3rem;
          font-weight: bold;
          font-family: 'PingFang SC';
          font-size: 0.32rem;
          flex: 1;
          line-height: .32rem;
        }
      }

      .brand-list {
        display: flex;
        flex-flow: wrap;
        margin: 0 0.2rem;
        background-color: #fff;
        border-radius: 0.1rem;
        padding-top: 0.2rem;
        .brand-item {
          width: 25%;
          text-align: center;
          font-size: 0.28rem;
          margin-bottom: 0.4rem;

          .brand-logo {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            text-align: center;
            margin-bottom: 20 rpx;
          }

        }
      }

    }
  }
</style>
